<script>
import InventoryListItem from "./InventoryListItem.vue";
import { INVENTORY_ITEMS } from "./InventoryQuery.gql";

import {
  SORT_ORDER,
  VxDataList,
  PaginationOptionsUtils,
  ServerPaginationUtils,
} from "@/core-ui";

export default {
  name: "InventoryListView",
  components: {
    VxDataList,
    InventoryListItem,
  },
  props: {
    parsedFilter: {
      type: Object,
      default: undefined,
    },
    storeId: {
      type: String,
      default: undefined,
    },
  },
  data() {
    const defaultPaginationOptions =
      PaginationOptionsUtils.buildDefaultPaginationOptions();
    const defaultSortOption = PaginationOptionsUtils.buildDefaultSortOptions({
      sortBy: "drsSki",
      sortOrder: SORT_ORDER.DESC,
    });

    return {
      inventoryItemsLoading: 0,
      // TODO - see if this is needed as init data or not
      inventoryItems: [],
      paginationOptions: defaultPaginationOptions,
      sortOptions: defaultSortOption,
      serverPaginationRequest:
        PaginationOptionsUtils.buildDefaultServerPaginationRequest(
          defaultPaginationOptions,
          defaultSortOption
        ),
    };
  },
  apollo: {
    inventoryItems: {
      query: INVENTORY_ITEMS,
      fetchPolicy: "cache-and-network",
      loadingKey: "inventoryItemsLoading",
      variables() {
        return this.queryVariables;
      },
      skip() {
        return !this.storeId;
      },
      update({ inventoryItems = { edges: [] } }) {
        ServerPaginationUtils.updateOptionsFromServerData(
          this.paginationOptions,
          inventoryItems
        );

        return inventoryItems.edges.map(({ node }) => node);
      },
    },
  },
  computed: {
    isSmallScreen() {
      // TODO - put this in some common lib
      return this.$vuetify.breakpoint.smAndDown;
    },
    headers() {
      return [
        {
          propertyPath: "image",
          label: "",
          sortable: false,
          cssClass: "image-cell",
        },
        {
          propertyPath: "drsSki",
          label: "DRS SKI",
          sortable: true,
          cssClass: "drs-ski-cell",
        },
        {
          propertyPath: "title",
          label: "Title",
          sortable: false,
          cssClass: "title-cell",
        },
        {
          propertyPath: "drsPriceCurrent",
          label: "Price",
          sortable: true,
          cssClass: "price-cell",
        },
        {
          propertyPath: "productType",
          label: "Product Type",
          sortable: false,
          cssClass: "product-cell",
        },
        {
          propertyPath: "location",
          label: "Location",
          sortable: true,
          cssClass: "store-cell",
        },
        {
          propertyPath: "shopifyId",
          label: "Channels",
          sortable: false,
          cssClass: "channels-cell",
        },
      ];
    },
    queryVariables() {
      return {
        storeId: this.storeId,
        ...this.serverPaginationRequest,
        filter: this.parsedFilter.GQLdata,
      };
    },
  },
  methods: {
    onShowMore() {
      if (this.isSmallScreen) {
        // When loading data from server, mobile is handled via load more
        let mobileServerPaginationRequest = {};
        ServerPaginationUtils.updatePage(
          this.paginationOptions,
          this.sortOptions,
          mobileServerPaginationRequest,
          this.paginationOptions.page + 1
        );
        ServerPaginationUtils.fetchMoreData(
          this.$apollo.queries,
          "inventoryItems",
          "inventoryItems",
          {
            ...this.queryVariables,
            ...mobileServerPaginationRequest,
          }
        );
      }
    },
    onPageUpdate(page) {
      ServerPaginationUtils.updatePage(
        this.paginationOptions,
        this.sortOptions,
        this.serverPaginationRequest,
        page
      );
    },
    onItemsPerPageUpdate(itemsPerPage) {
      ServerPaginationUtils.updateItemsPerPage(
        this.paginationOptions,
        this.sortOptions,
        this.serverPaginationRequest,
        itemsPerPage
      );
    },
    onSortUpdate(header) {
      ServerPaginationUtils.updateSort(
        this.paginationOptions,
        this.sortOptions,
        this.serverPaginationRequest,
        header.propertyPath
      );
    },
    selectInventoryItem(inventoryItem) {
      this.$router.push({
        name: "inventory_show",
        params: { inventoryItemId: inventoryItem.id },
        preserveQuery: true,
      });
    },
  },
};
</script>

<template>
  <VxDataList
    class="inventory-list"
    :headers="headers"
    :pagination-options="paginationOptions"
    :sort-options="sortOptions"
    :loading="inventoryItemsLoading"
    :empty-data-text="'No inventory items.'"
    :data-loading-text="'Loading inventory.'"
    @update:page="onPageUpdate"
    @update:itemsPerPage="onItemsPerPageUpdate"
    @update:sort="onSortUpdate"
    @showMore="onShowMore"
  >
    <template #body>
      <InventoryListItem
        v-for="inventoryItem in inventoryItems"
        :key="inventoryItem.id"
        :item="inventoryItem"
        @show="selectInventoryItem"
      />
    </template>
  </VxDataList>
</template>

<style lang="scss">
.inventory-list {
  .image-cell {
    width: 140px;
  }

  .drs-ski-cell {
    width: 200px;
  }

  .title-cell {
    width: 250px;
  }

  .price-cell {
    width: 120px;
  }

  .product-cell {
    /* THIS one has auto width */
  }

  .store-cell {
    width: 200px;
  }

  .channels-cell {
    width: 100px;
  }
}
</style>
