<script>
import { VxDataListItem } from "@/core-ui";
import { hasSnackbarAccess } from "@/mixins/ui";
import { materialAbsoluteDateTimeString, materialTime } from "@/utils/datetime";
import upperFirst from "lodash/upperFirst";

export default {
  name: "InventoryListItem",
  components: {
    VxDataListItem,
  },
  mixins: [hasSnackbarAccess],
  inheritAttrs: false,
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    date() {
      if (!this.item) return "";
      if (!this.item.createdAt) return "";

      // return format(this.item.createdAt, "M/D/YYYY [at] h:mm a");
      return upperFirst(
        materialAbsoluteDateTimeString(this.item.createdAt, true)
      );
    },
    timeStr() {
      if (!this.item) return "";
      if (!this.item.createdAt) return "";

      // return format(this.item.createdAt, "h:mm a");
      return materialTime(this.item.createdAt);
    },
    thumbnailImage() {
      if (this.item.imageCount <= 0) return null;
      return `/product_image/${this.$route.params.storeId}-${this.item.id}-0-250`;
    },
  },
  methods: {
    showInventoryItem() {
      this.$emit("show", this.item);
    },
  },
};
</script>

<template>
  <VxDataListItem
    v-bind="$attrs"
    class="item rai-data"
    v-on="$listeners"
    @click.stop="showInventoryItem"
  >
    <template #desktop>
      <td class="pa-2 pl-6">
        <v-img
          :src="thumbnailImage || '/img/no_img.png'"
          width="100"
          aspect-ratio="1"
          contain
        />
      </td>
      <td class="font-weight-bold">
        {{ item.drsSki }}
      </td>
      <td>{{ item.title || item.drsDescription }}</td>
      <td>{{ $filters.dollars(item.drsPriceCurrent) }}</td>
      <td>{{ item.productType || item.drsSubcat }}</td>
      <td>{{ item.location }}</td>
      <td>
        <v-icon :color="!!item.shopifyId ? 'primary' : ''"
          >$vuetify.icons.shopify</v-icon
        >
      </td>
    </template>

    <template #mobile>
      <td class="pa-2 pl-0">
        <v-img
          :src="thumbnailImage || '/img/no_img.png'"
          width="75"
          min-height="75"
          aspect-ratio="1"
          contain
        />
      </td>
      <v-list-item-content>
        <v-list-item-subtitle>
          <span class="font-weight-bold rai-data text--secondary">
            {{ item.location }}
          </span>
        </v-list-item-subtitle>
        <v-list-item-title
          class="font-weight-bold rai-data"
          v-text="item.drsSki"
        />
        <v-list-item-subtitle>
          <span class="text-truncate d-block">{{
            item.title || item.drsDescription
          }}</span>
          <span class="text-truncate d-block">{{
            $filters.dollars(item.drsPriceCurrent)
          }}</span>
        </v-list-item-subtitle>
      </v-list-item-content>
      <v-list-item-action class="mt-8">
        <v-icon :color="!!item.shopifyId ? 'primary' : ''">$shopify</v-icon>
      </v-list-item-action>
    </template>
  </VxDataListItem>
</template>

<style lang="scss">
tr.rai-orlid {
  td {
    height: 40px;
    padding-left: 0;
  }
  td.tdicon {
    width: 40px;
    padding-left: 12px;
    padding-right: 6px;
    padding-top: 0;
    padding-bottom: 0;
    svg {
      margin: auto;
    }
  }
  .w-ch-10 {
    width: 10ch;
  }
}
tr.rai-orlid--closed {
  background-color: var(--v-ui-lighten5);
  color: rgba(0, 0, 0, 0.6);
}
</style>
